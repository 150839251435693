import React from "react";
import {graphql} from "gatsby";
import Layout from "components/Layout";
import {contentfulRenderer} from "../utils/contentfulRenderer";
import {PropsWithTranslationSheet} from "../i18n/TranslationSheet";
import AmazonBanner from "components/AmazonBanner";
import {
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton
} from "react-share";

export const blogPostTemplateQuery = graphql`
    query BlogPostTemplate($id: String!) {
        contentfulBlogPost(id: {eq: $id}) {
            id
            title
            metaDescription
            content {
                raw
                references {
                    ... on ContentfulAsset {
                        __typename
                        title
                        file {
                            url
                        }
                        contentful_id
                    }
                }
            }
            createdAt
            updatedAt
            subtitle
            slug
            image {
                file {
                    url
                }
                title
            }
        }
    }
`;

const BlogPostTemplate: React.FC<
    PropsWithTranslationSheet & {data: any; pageContext: {slug: string}}
> = ({data, pageContext: {slug, t}}) => {
    const {
        title,
        createdAt,
        updatedAt,
        content,
        metaDescription,
        image: {
            file: {url},
            title: imageTitle
        }
    } = data.contentfulBlogPost;

    return (
        <Layout
            t={t}
            isPost
            slug={slug}
            title={title}
            metaDescription={metaDescription}
            cardImagePath={url}
            datePublished={new Date(createdAt)}
            dateUpdated={new Date(updatedAt)}
        >
            <div className="relative">
                <div className="absolute top-3 flex justify-end gap-3 w-full">
                    <FacebookShareButton url={t.ROOT_URL + "/" + slug} title={title}>
                        <FacebookIcon size={30} className={"rounded"} />
                    </FacebookShareButton>

                    <FacebookMessengerShareButton
                        url={t.ROOT_URL + "/" + slug}
                        appId={"142691081113133"}
                    >
                        <FacebookMessengerIcon size={30} className={"rounded"} />
                    </FacebookMessengerShareButton>
                </div>
            </div>

            <main className="lg:mt-10">
                <img
                    loading="eager"
                    src={`https:${url}`}
                    className="w-full object-contain lg:rounded h-60 mb-8"
                    alt={imageTitle}
                />

                <AmazonBanner t={t} />
                <div className="flex flex-col lg:flex-row lg:space-x-12">
                    <div className="prose post-container mt-8 text-gray-700 text-lg leading-relaxed w-full">
                        {contentfulRenderer(content)}
                    </div>
                </div>
            </main>
        </Layout>
    );
};

export default BlogPostTemplate;
